import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ModalContext } from "../contexts/modalContext"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = ({ siteTitle }) => {
  const { handleModal } = useContext(ModalContext)

  return (
    <header id="masthead" className="site-header">
      <div className="grid-container">
        <div className="site-branding">
          <p className="site-title">
            <Link to={"/"} rel="home">
              Etre un leader visionnaire
            </Link>
          </p>
        </div>

        <nav id="site-navigation" className="main-navigation">
          <div className="menu-main-container">
            <ul id="primary-menu" className="menu">
              <li
                id="menu-item-61"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-61"
              >
                <button onClick={() => handleModal()}>Commander</button>
              </li>
              <li
                id="menu-item-71"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-71"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://evaluations.motiveparlessentiel.org/vision/"
                >
                  Évaluation
                </a>
              </li>
              <li
                id="menu-item-62"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-62"
              >
                <button onClick={() => scrollTo("#buy")}>
                  Ressources gratuites
                </button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
