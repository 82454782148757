import React, { createContext } from "react"
import useModal from "../hooks/useModal"
import useBonusesModal from "../hooks/useBonusesModal"
import BuyModal from "../components/buy-modal"
import BonusesModal from "../components/bonuses-modal"

let ModalContext

const { Provider } = (ModalContext = createContext())

const ModalProvider = ({ children }) => {
  const { modal, handleModal, modalContent } = useModal()
  const { bonusesModal, handleBonusesModal } = useBonusesModal()
  return (
    <Provider
      value={{
        modal,
        handleModal,
        bonusesModal,
        handleBonusesModal,
        modalContent,
      }}
    >
      {children}
      <BuyModal />
      <BonusesModal />
    </Provider>
  )
}

export { ModalContext, ModalProvider }
