import React, { useContext } from "react"
import { ModalContext } from "../contexts/modalContext"
import { motion } from "framer-motion"

const variants = {
  open: { opacity: 1, display: "block" },
  closed: { opacity: 0, transitionEnd: { display: "none" } },
}
const BuyModal = props => {
  const { handleModal, modal } = useContext(ModalContext)

  return (
    <motion.div
      initial={"closed"}
      animate={modal ? "open" : "closed"}
      variants={variants}
      id="buy-modal"
      className="mh-modal"
      style={{ display: "block" }}
    >
      <button
        style={{ border: "none" }}
        className="close"
        onClick={() => handleModal()}
        aria-label={"Fermer la fenêtre modale"}
      >
        <i className="fal fa-times" />
      </button>

      <div className="modal-window">
        <div className="modal-content">
          <span>Vous êtes en Europe&nbsp;:</span>
          <ul>
            <li>
              <a
                className="logo"
                target="_blank"
                rel={"noopener noreferrer"}
                href="https://www.maisonbible.net"
              >
                En librairie
              </a>
              <a
                className="button"
                target="_blank"
                rel={"noopener noreferrer"}
                href="https://www.maisonbible.net"
              >
                En libraire
              </a>
            </li>
            <li>
              <a
                className="logo"
                target="_blank"
                rel={"noopener noreferrer"}
                href="https://www.amazon.fr/Être-leader-visionnaire-développer-entreprise/dp/2940413916/ref=sr_1_4?__mk_fr_FR=ÅMÅŽÕÑ&dchild=1&keywords=être+un+leader+visionnaire&qid=1594126133&sr=8-4"
              >
                Amazon
              </a>
              <a
                className="button"
                target="_blank"
                rel={"noopener noreferrer"}
                href="https://www.amazon.fr/Être-leader-visionnaire-développer-entreprise/dp/2940413916/ref=sr_1_4?__mk_fr_FR=ÅMÅŽÕÑ&dchild=1&keywords=être+un+leader+visionnaire&qid=1594126133&sr=8-4"
              >
                Commander
              </a>
            </li>
          </ul>
          <span>Vous êtes en Afrique ou Haïti :</span>
          <ul>
            <li>
              <a
                className="logo livrafique"
                target="_blank"
                rel={"noreferrer noopener"}
                href="https://visiondrivenleader.com/buy-barnesandnoble"
              >
                Livra'Afrique
              </a>
              <a
                className="button"
                target="_blank"
                rel={"noreferrer noopener"}
                href="https://www.livrafrique.com"
              >
                Commander
              </a>
            </li>
          </ul>
          <span>Vous êtes en Amérique du Nord ou Canada :</span>
          <ul>
            <li>
              <a
                className="logo clccanada"
                target="_blank"
                rel={"noreferrer noopener"}
                href="https://store.clccanada.com"
              >
                En librairie
              </a>
              <a
                className="button"
                target="_blank"
                rel={"noreferrer noopener"}
                href="https://store.clccanada.com"
              >
                Commander
              </a>
            </li>
          </ul>
          <span>Vous êtes un libraire en Europe :</span>
          <ul>
            <li>
              <a
                href="https://diffusion.livreetplus.com/fr/"
                className="logo livreetplus"
              >
                Livre et plus diffusion
              </a>
              <a
                href="https://diffusion.livreetplus.com/fr/"
                className="button"
              >
                Commander
              </a>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  )
}

export default BuyModal
