import { useState } from "react"

export default () => {
  const [modal, setModal] = useState(false)

  const handleModal = () => {
    setModal(!modal)
    document.querySelector("#page").classList.toggle("blurred")
  }
  return { modal, handleModal }
}
