import { useState } from "react"

export default () => {
  const [bonusesModal, setBonusesModal] = useState(false)

  const handleBonusesModal = () => {
    document.querySelector("#page").classList.toggle("blurred")
    setBonusesModal(!bonusesModal)
  }
  return { bonusesModal, handleBonusesModal }
}
