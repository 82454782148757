import React, { useContext, useState } from "react"
import { motion } from "framer-motion"
import { ModalContext } from "../contexts/modalContext"
import Loader from "./loader"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import axios from "axios"

const variants = {
  open: { display: "block", opacity: 1 },
  closed: { opacity: 0, transitionEnd: { display: "none" } },
}

const BonusesModal = props => {
  const [message, setMessage] = useState("")
  const { register, handleSubmit, errors } = useForm()
  //const codebonus = "GBEUleaVI07"
  const regexCode = /GBEU[liI]eaV[liL]07/gim
  const { bonusesModal, handleBonusesModal } = useContext(ModalContext)
  const [isSubmitting] = useState(false)

  function navigateToBonuses() {
    const urlBonus = `https://digital.motiveparlessentiel.org/purchase?product_id=2349760`
    navigate(urlBonus)
  }

  const onSubmit = async data => {
    console.log(data)

    if (data.inputCode.match(regexCode)) {
      try {
        const response = await axios.post("/.netlify/functions/convertkit", {
          email: data.inputEmail,
          fname: data.inputPrenom,
          lname: data.inputNom,
          tags: [5292191],
        })

        if (response.status === 200) {
          navigateToBonuses()
        }
      } catch (error) {
        setMessage(
          "Error: " +
            (error.response?.data?.error || "Une erreur est survenue"),
        )
      }
    } else {
      setMessage("Code invalide")
    }
  }

  return (
    <motion.div
      initial={"closed"}
      animate={bonusesModal ? "open" : "closed"}
      variants={variants}
      id="bonuses-modal"
      className="mh-modal"
      style={{ display: "block" }}
    >
      <button
        className="close"
        onClick={() => handleBonusesModal()}
        aria-label={"Fermer la fenêtre modale"}
      >
        <i className="fa fa-times" />
      </button>
      <div className="modal-window">
        <h2>
          Recevez vos <span className="highlighted">Bonus</span>
        </h2>
        <div className="modal-content">
          <div className="grid-container">
            <div className="grid-100">
              <p>
                Afin de recevoir vos bonus, veuillez svp remplir les champs
                ci-dessous :{" "}
              </p>
            </div>
          </div>
          <div className="hbspt-form">
            <form
              className={"form"}
              style={{ position: "relative" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {isSubmitting && <Loader />}
              <div className="form-row">
                <div className="grid-50">
                  <label htmlFor="inputNom">Nom</label>
                  <input
                    type="text"
                    name={"inputNom"}
                    id="inputNom"
                    ref={register({ required: true })}
                  />
                  {errors.inputNom && (
                    <span style={{ color: "red" }}>
                      Veuillez renseigner votre nom
                    </span>
                  )}
                </div>
                <div className="grid-50">
                  <label htmlFor="inputPrenom">Prénom</label>
                  <input
                    type="text"
                    name={"inputPrenom"}
                    id="inputPrenom"
                    ref={register({ required: true })}
                  />
                  {errors.inputPrenom && (
                    <span style={{ color: "red" }}>
                      Veuillez renseigner votre prénom
                    </span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="grid-50">
                  <label htmlFor="inputEmail">Email</label>
                  <input
                    type="email"
                    name={"inputEmail"}
                    id="inputEmail"
                    ref={register({ required: true })}
                  />
                  {errors.inputEmail && (
                    <span style={{ color: "red" }}>
                      Veuillez renseigner un email valide
                    </span>
                  )}
                </div>
                <div className="grid-50">
                  <label htmlFor="inputCode">Code du livre</label>
                  <input
                    type="text"
                    name={"inputCode"}
                    id="inputCode"
                    ref={register({ required: true })}
                  />
                  {errors.inputCode && (
                    <span style={{ color: "red" }}>
                      Veuillez renseigner le code trouvé à la page 4 du livre
                    </span>
                  )}
                </div>
              </div>
              {message && (
                <div className="form-row">
                  <div className="grid-100">
                    <span style={{ color: "red" }}>{message}</span>
                  </div>
                </div>
              )}
              <div className="form-row">
                <div className="hs_submit hs-submit">
                  <div className="actions">
                    <input type="submit" value="Envoyer" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

BonusesModal.propTypes = {}

export default BonusesModal
